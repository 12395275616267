<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 대상년도 -->
          <c-datepicker
            type="year"
            default="today"
            label="LBL0010014"
            name="year"
            v-model="searchParam.year"
          />
        </div>
      </template>
    </c-search-box>
    <!--안전보건 목표 및 추진계획/실적-->
    <c-table
      ref="table"
      title="관계법령 의무사항 이행 추진계획 및 실적"
      :columns="grid.columns"
      :data="grid.data"
      rowKey="mainSafetyHealthyTargetId"
      :usePaging="false"
      :isExcelDown="false"
      :filtering="true"
      :isFullScreen="false"
      :columnSetting="false"
      :selection="popupParam.type"
      @rowDblclick="rowDblclick"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <!--검색-->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          <!--선택-->
          <c-btn v-if="editable" label="LBLSELECT" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'safety-healthy-target-popup',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        type: 'multiple',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        year: '',
      },
      editable: true,
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            //사업장
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'targetYear',
            field: 'targetYear',
            //대상년도
            label: 'LBL0010014',
            align: 'center',
            sortable: true,
            style: 'width:120px',
          },
          {
            name: 'targetTitle',
            field: 'targetTitle',
            // 제목
            label: '제목',
            align: 'center',
            sortable: true,
            style: 'width:150px',
            type: 'link'
          },
          {
            name: 'writeDeptName',
            field: 'writeDeptName',
            //작성부서
            label: 'LBLWRITEDEPT',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'writeDt',
            field: 'writeDt',
            //작성일
            label: 'LBLREGDATE',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
        ],
        data: [],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.mainsafetyHealthy.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '하나 이상 선택하세요.',  // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>